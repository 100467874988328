const picUtils = {
    methods: {
        handleSize(file) {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    let img = new Image();
                    img.src = reader.result;
                    if (img.complete) { // 如果存在浏览器缓存中
                        resolve({
                            width: img.width,
                            height: img.height
                        })
                    } else {
                        img.onload = () => {
                            resolve({
                                width: img.width,
                                height: img.height
                            })
                        }
                    }
                }
            })
        },
        handleBASE64(file){
            return new Promise((resolve) => {
                let reader = new FileReader();
                reader.onload = () => {
                    let _base64 = reader.result;
                    let BASE64 = _base64.split(",");
                    resolve(BASE64[1])
                }
                reader.readAsDataURL(file)
            })
        },
    },
}

export { picUtils }
