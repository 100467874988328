<template>
    <div class="member-edit">
        <!-- 返回上一页 -->
        <div class="member-edit-nav">
            <a href="javascript:;"
                class="member-edit-nav-back"
                @click="$router.go(-1)">
                返回上一步
            </a>
            <span>/</span>
            <span>上传</span>
        </div>
        <!-- 表单 -->
        <div class="member-edit-form">
            <el-form
                :model="form"
                :rules="rules"
                ref="editForm"
                label-width="100px">
                <!-- 名称 -->
                <el-form-item
                    label="名称">
                    <el-input
                        v-model="form.goods_name"
                        class="member-edit-form-name"
                        placeholder="请输入名称">
                    </el-input>
                </el-form-item>
                <!-- 封面图片 -->
                <el-form-item
                    label="封面图片">
                    <div class="member-edit-form-remarks">
                        建议图片大小2M以内
                    </div>
                    <el-upload
                        action="#"
                        list-type="picture-card"
                        :auto-upload="false"
                        :file-list="form.goods_cover"
                        :limit="1"
                        :on-change="handleCover"
                        :on-remove="removeCover"
                        :class="[
                            'member-edit-form-pic',
                            form.goods_cover.length > 0? 'member-edit-pic-hidden': ''
                        ]"
                        class="member-edit-form-pic">
                        <template #trigger>
                            <i class="el-icon-plus"></i>
                        </template>
                    </el-upload>
                </el-form-item>
                <!-- 详情图 -->
                <el-form-item
                    label="详情图">
                    <div class="member-edit-form-remarks">
                        建议图片大小2M以内（最多4张）
                    </div>
                    <el-upload
                        action="#"
                        list-type="picture-card"
                        :auto-upload="false"
                        :file-list="form.goods_pic"
                        :limit="4"
                        :on-change="handlePreview"
                        :on-remove="removePreview"
                        :class="[
                            'member-edit-form-pic',
                            form.goods_pic.length > 3? 'member-edit-pic-hidden': ''
                        ]"
                        class="member-edit-form-pic">
                        <template #trigger>
                            <i class="el-icon-plus"></i>
                        </template>
                    </el-upload>
                </el-form-item>
                <!-- 时间段 -->
                <el-form-item
					v-if="form.serviceType==='1'" 
                    label="服务时间段">
                    <el-time-select
                        v-model="form.openBegin"
                        :picker-options="{
                            start: '00:00',
                            step: '01:00',
                            end: '24:00'
                        }">
                    </el-time-select>
                    -
                    <el-time-select
                        v-model="form.openEnd"
                        :picker-options="{
                            start: '00:00',
                            step: '01:00',
                            end: '24:00'
                        }">
                    </el-time-select>
                </el-form-item>
				
				<el-form-item
					v-if="!needService"
				    label="服务时间段">
				    <el-time-select
				        v-model="form.openBegin"
				        :picker-options="{
				            start: '00:00',
				            step: '01:00',
				            end: '24:00'
				        }">
				    </el-time-select>
				    -
				    <el-time-select
				        v-model="form.openEnd"
				        :picker-options="{
				            start: '00:00',
				            step: '01:00',
				            end: '24:00'
				        }">
				    </el-time-select>
				</el-form-item>
				
				
                <!-- 服务/实体 -->
                <el-form-item
                    label="服务类型"
                    v-if="needService">
                    <el-radio v-model="form.serviceType"
                        label="1">
                        服务
                    </el-radio>
                    <el-radio v-model="form.serviceType"
                        label="2">
                        实体商品（备注：自己做的服装、手工制品）
                    </el-radio>
                </el-form-item>
                <!-- 价格 -->
                <el-form-item
                    label="价格">
                    <div class="member-edit-form-price">
                        <el-input
                            v-model="form.goods_price"
                            class="member-edit-form-price-input"
                            placeholder="请输入价格">
                        </el-input>
                        <span>/</span>
                        <el-input
                            v-model="form.price_unit"
                            class="member-edit-form-price-input"
                            placeholder="请输入单位">
                        </el-input>
                        <div class="member-edit-form-remarks">
                            例：200元/小时
                        </div>
                    </div>
                </el-form-item>
                <!-- 所在地区 -->
                <el-form-item
                    label="所在地区">
                    <area-cascader 
                        v-model="form.area"
                        type='text'
                        :level='1'
                        :data="$pcaa"
                        placeholder="请选择地址"
                        class="member-edit-form-area">
                    </area-cascader>
                </el-form-item>
                <!-- 详细地址 -->
                <el-form-item
                    label="详细地址">
                    <el-input
                        v-model="form.address"
                        class="member-edit-form-address"
                        placeholder="请输入详细地址"
                        @keyup.enter.native="handleSearch">
                        <el-button 
                            slot="append" 
                            icon="el-icon-search"
                            @click="handleSearch">
                        </el-button>
                    </el-input>
                    <!-- 
                            :map-change="setLeaseDetailChange" -->
                    <div class="member-edit-form-address-map">
                        <b-map
                            :map-height="300"
                            :map-position="position"
                            ref="mapSearch">
                        </b-map>
                    </div>
                </el-form-item>
                <!-- 描述 -->
                <el-form-item
                    label="描述">
                    <el-input
                        v-model="form.describe"
                        type="textarea"
                        :rows="2"
                        placeholder="请输入简介">
                    </el-input>
                </el-form-item>
                <!-- 上传 -->
                <el-form-item>
                    <el-button
                        type="warning"
                        @click="handleClick">
                        上传
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import { picUtils } from "@/tool/picUtils.js";

    export default {
        mixins: [ picUtils ],
        data() {
            return {
                form: {
                    goods_name: "",
                    goods_cover: "",
                    goods_pic: [],
                    openBegin: '',
                    openEnd: '',
                    serviceType: '',
                    goods_price: "",
                    price_unit: "",
                    area: [],
                    address: "",
                    describe: "",
                },
                rules: {},
                position: {
                    lat: "", // 纬度
                    lng: "", // 经度
                }
            }
        },
        props: {
            detail: {
                type: Object,
                default() {return {}}
            },
            needService: {
                type: Boolean,
                default: true
            },
			id: {
			    type: String,
			    
			},
            handleImage: Function
        },
        components: {
            bMap: () => import("@/components/bMap")
        },
        watch: {
            detail: {
                handler: function(value) {
                    let {
                        name: goods_name = '',
                        skin = '',
                        detail = [],
                        open_begin: openBegin = '',
                        open_end: openEnd = '',
                        sketch: describe = '',
                        price: goods_price = '',
                        unit: price_unit = '',
                        area = [],
                        address = '',
                        goodsType = '',
                        position = {
                            lat: '',
                            long: '',
                        }
                    } = value,
                    goods_cover = [],
                    goods_pic = [],
                    serviceType = String(goodsType);
                    
                    // el-upload组件file-list参数，需要起码添加url属性
                    // 以下为对图片原始数据的处理
                    skin != '' && goods_cover.push({ url: skin });
                    if(JSON.stringify(detail) != '[]'){
                        detail.map(item => {
                            goods_pic.push({ url: item });
                        })
                    }

                    this.form = {
                        goods_name,
                        goods_cover,
                        goods_pic,
                        describe,
                        openBegin,
                        openEnd,
                        goods_price,
                        price_unit,
                        area,
                        address,
                        serviceType
                    }
                    this.position = {
                        lat: position.lat,
                        lng: position.long
                    }
                },
                immediate: true
            }
        },
        methods: {
            handleSearch(){ // 搜索功能
                let val = this.form.area.join("");
                if(this.form.address){
                    val = val.concat(this.form.address)
                }
                this.$refs.mapSearch.handleSearch(val)
            },
            handleCover(file, fileList) {
                this.handleBASE64(file.raw)
                .then(res => {
                    return this.handleImage(res)
                }).then(res => {
                    this.form.goods_cover.push({
                        status: 'success',
                        url: res.result
                    })
                })
            },
            removeCover(file, fileList) {
                this.form.goods_cover.map((item, index) => {
                    if(item.url == file.url){
                        this.form.goods_cover.splice(index, 1);
                    }
                })
            },
            handlePreview(file, fileList) {
                this.handleBASE64(file.raw)
                .then(res => {
                    return this.handleImage(res)
                }).then(res => {
                    this.form.goods_pic.push({
                        status: 'success',
                        url: res.result
                    })
                })
            },
            removePreview(file, fileList) {
                this.form.goods_pic.map((item, index) => {
                    if(item.url == file.url){
                        this.form.goods_pic.splice(index, 1);
                    }
                })
            },
            handleClick() {
				// console.log(this.form)
                this.$emit('handle-submit', this.form, this.position);
            }
        }
    }
</script>

<style scoped>
/* 导航 */
.member-edit-nav-back{
    color: #8d9afc;
}
.member-edit-nav > span{
    margin-left: 10px;
    color: #909399;
}
/* 表单 */
.member-edit-form{
    margin-top: 30px;
}
/* 备注 */
.member-edit-form-remarks{
    color: #e6a23c;
}
/* 输入框 */
.member-edit-form-name{
    width: 200px;
}
/* 图片 */
.member-edit-form-pic >>> .el-upload-list__item-thumbnail{
    object-fit: scale-down;
}
.member-edit-pic-hidden >>> .el-upload {
    display: none;
}
/* 价格 */
.member-edit-form-price{
    display: flex;
    justify-content: space-between;
    width: 480px;
}
.member-edit-form-price-input{
    width: 160px;
}
/* 地区 */
.member-edit-form-area >>> .area-select{
    height: 40px;
}
.member-edit-form-area >>> .area-selected-trigger{
    padding: 0 20px 0 12px;
}
/* 地址 */
.member-edit-form-address{
    width: 260px;
    margin-bottom: 10px;
}
</style>